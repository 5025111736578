<template>
	<div class="mx-auto">
		<div class="grid grid-cols-12 h-screen">
			<div class="col-span-2 flex flex-col text-white left">
				<Sidebar />
			</div>
			<div class="grid col-span-10 p-4">
				<div class="flex flex-col">

					<div class="flex flex-none flex-row px-4 pt-3">
						<div class="mr-auto bg-blue-300"></div>
						<div class="ml-auto">
							<button class="rounded px-4 py-2 text-white bg-blue-500 hover:bg-blue-600"><i class="fa fa-plus mr-2"></i>Add</button>
						</div>
					</div>

					<div class="flex-1 p-4">
						<table class="table-fixed w-full">
							<thead>
								<tr>
									<th class="py-2 w-1/12">ID</th>
									<th class="py-2 w-7/12">Title</th>
									<th class="py-2 w-2/12">Created At</th>
									<th class="py-2 w-2/12">Updated At</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in items" :key="item.id">
									<td class="py-2 text-center">{{ item.id }}</td>
									<td class="py-2 px-3">{{ item.title }}</td>
									<td class="py-2 text-center">{{ new Date(item.timestamps.created * 1000).toLocaleString("en-US", { timeZone: "Asia/Colombo" }) }}</td>
									<td class="py-2 text-center">{{ new Date(item.timestamps.updated * 1000).toLocaleString("en-US", { timeZone: "Asia/Colombo" }) }}</td>
								</tr>
							</tbody>
						</table>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Sidebar from "../components/Sidebar.vue"

export default {
	name: "Items",
	components: { Sidebar },

	mounted() {
		this.$store.dispatch("items/list", { page: 1 })
	},

	computed: {
		items() { return this.$store.state.items.items }
	}
}
</script>

<style scoped>
.left {
	background-color: #4338CA;
}

table thead tr th {
	background-color: #f6f8fc;
	border: 2px solid #dee2e6;
}

table tbody tr {
	border: 2px solid #dee2e6;
}

table thead tr th {
	color: #054FC7;
}

table tbody tr:hover {
	background-color: #eeeeee;
}
</style>
